var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user)?_c('div',{staticClass:"d-flex"},[(_vm.checkRequestLimit())?_c('v-alert',{attrs:{"type":"warning","border":"left","text":""}},[_vm._v(" You have reached the maximum number of active requests for your package. Check your active requests, mark pending requests as completed or pause some requests so you can submit more requests ")]):_c('div',{staticClass:"flex-grow-1"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',[_c('h5',{staticClass:"text-h5 mb-1 font-weight-bold"},[_vm._v(" What can we help you with? ")]),(
            _vm.user &&
            _vm.user.role == 'client' &&
            (_vm.user.requestPackage || _vm.user.requestPackages)
          )?_c('div',{staticClass:"caption grey--text"},[_c('span',{staticClass:"mr-2"},[_vm._v("Request Packages:")]),(_vm.user.requestPackages && _vm.user.requestPackages.length)?_c('div',{staticClass:"d-inline-flex align-center gap-5"},_vm._l((_vm.user.requestPackages),function(pkg){return _c('v-chip',{key:pkg,attrs:{"rounded":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.$store.getters["request/rPackage"](pkg).name)+" ")])}),1):_c('v-chip',{attrs:{"rounded":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.$store.getters["request/rPackage"](_vm.user.requestPackage).name)+" ")])],1):_vm._e()]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"href":"https://help.lightningsites.com/en/","target":"_blank","color":"primary"}},[_vm._v("Training Centre")]),_c('v-sheet',{staticClass:"flex-grow-1",attrs:{"max-width":"350"}},[_c('v-select',{staticClass:"box-shadow",attrs:{"items":_vm.orderBy(_vm.categories, 'name', 'asc'),"placeholder":"Filter by category","item-text":"name","item-value":"id","hide-details":"","clearable":"","outlined":"","dense":""},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})],1)],1),(_vm.typeStatus.getting || _vm.gettingProducts)?_c('preloader',{attrs:{"message":"Please wait..."}}):_vm._e(),(!_vm.typeStatus.getting && !_vm.confTypes.length)?_c('v-alert',{staticClass:"mt-5",attrs:{"type":"info","border":"left"}},[_vm._v(" No request type available. Update your request package. ")]):_vm._e(),(!_vm.typeStatus.getting && _vm.confTypes.length && _vm.viewTypes)?_c('v-fade-transition',{staticClass:"mt-10 d-flex gap-10 flex-wrap",attrs:{"group":""}},_vm._l((_vm.confTypes),function(type){return _c('type-card',{key:type.id,attrs:{"grey":!_vm.inPackage(type.id),"type":type,"noneRoute":"","action":""},on:{"selected":function($event){_vm.user.role === 'client' && !_vm.inPackage(type.id)
            ? (_vm.errorDialog = true)
            : _vm.$router.push({
                name: 'RequestForm',
                params: { id: type.ref.parent.parent.id, product: type.id },
              })}}})}),1):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":_vm.$dialog.content},model:{value:(_vm.errorDialog),callback:function ($$v) {_vm.errorDialog=$$v},expression:"errorDialog"}},[_c('close-btn',{attrs:{"overflow":""},on:{"click":function($event){_vm.errorDialog = false}}}),_c('v-card',[_c('v-card-title',[_c('h5',{staticClass:"text-h5 font-weight-bold"},[_vm._v("Upgrade Required")])]),_c('v-card-text',[_c('p',[_vm._v("To make these types of requests please update your care plan.")]),_c('v-btn',{attrs:{"to":{
            name: 'RequestForm',
            params: {
              id: 'gbzvRo1U9LEAlV6b3ifv',
              product: 'doyhKwJDZkZo4L0rTnAb',
            },
          },"color":"primary","small":""}},[_vm._v("Upgrade Now")])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }