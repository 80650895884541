<template>
  <div v-if="user" class="d-flex">
    <v-alert v-if="checkRequestLimit()" type="warning" border="left" text>
      You have reached the maximum number of active requests for your package.
      Check your active requests, mark pending requests as completed or pause
      some requests so you can submit more requests
    </v-alert>

    <div v-else class="flex-grow-1">
      <div class="d-flex align-center">
        <div>
          <h5 class="text-h5 mb-1 font-weight-bold">
            What can we help you with?
          </h5>

          <div
            v-if="
              user &&
              user.role == 'client' &&
              (user.requestPackage || user.requestPackages)
            "
            class="caption grey--text"
          >
            <span class="mr-2">Request Packages:</span>
            <div
              v-if="user.requestPackages && user.requestPackages.length"
              class="d-inline-flex align-center gap-5"
            >
              <v-chip
                v-for="pkg of user.requestPackages"
                :key="pkg"
                rounded
                outlined
                small
              >
                {{ $store.getters["request/rPackage"](pkg).name }}
              </v-chip>
            </div>
            <v-chip v-else rounded outlined small>
              {{ $store.getters["request/rPackage"](user.requestPackage).name }}
            </v-chip>
          </div>
        </div>

        <v-spacer></v-spacer>

        <v-btn href="https://help.lightningsites.com/en/" target="_blank" color="primary" class="mr-2">Training Centre</v-btn>

        <v-sheet max-width="350" class="flex-grow-1">
          <v-select
            :items="orderBy(categories, 'name', 'asc')"
            placeholder="Filter by category"
            v-model="category"
            class="box-shadow"
            item-text="name"
            item-value="id"
            hide-details
            clearable
            outlined
            dense
          ></v-select>
        </v-sheet>
      </div>

      <preloader
        v-if="typeStatus.getting || gettingProducts"
        message="Please wait..."
      />

      <!-- TYPES -->
      <v-alert
        v-if="!typeStatus.getting && !confTypes.length"
        class="mt-5"
        type="info"
        border="left"
      >
        No request type available. Update your request package.
      </v-alert>

      <v-fade-transition
        v-if="!typeStatus.getting && confTypes.length && viewTypes"
        class="mt-10 d-flex gap-10 flex-wrap"
        group
      >
        <type-card
          v-for="type in confTypes"
          @selected="
            user.role === 'client' && !inPackage(type.id)
              ? (errorDialog = true)
              : $router.push({
                  name: 'RequestForm',
                  params: { id: type.ref.parent.parent.id, product: type.id },
                })
          "
          :grey="!inPackage(type.id)"
          :key="type.id"
          :type="type"
          noneRoute
          action
        ></type-card>
      </v-fade-transition>
    </div>

    <!-- ERROR DIALOG -->
    <v-dialog v-model="errorDialog" :max-width="$dialog.content">
      <close-btn @click="errorDialog = false" overflow />

      <v-card>
        <v-card-title>
          <h5 class="text-h5 font-weight-bold">Upgrade Required</h5>
        </v-card-title>
        <v-card-text>
          <p>To make these types of requests please update your care plan.</p>

          <v-btn
            :to="{
              name: 'RequestForm',
              params: {
                id: 'gbzvRo1U9LEAlV6b3ifv',
                product: 'doyhKwJDZkZo4L0rTnAb',
              },
            }"
            color="primary"
            small
            >Upgrade Now</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { orderBy } from "lodash";
import { mapState, mapActions } from "vuex";

import TypeCard from "./components/TypeCard";

export default {
  metaInfo: {
    title: "Create Request",
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      orderBy,
      newId: null,
      category: null,
      submitted: false,
      errorDialog: false,
      gettingPackage: true,
      gettingProducts: null,
    };
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      types: (state) => state.types.products,
      status: (state) => state.request.status,
      categories: (state) => state.types.types,
      typeStatus: (state) => state.types.status,
      fields: (state) => state.productform.fields,
      fieldStatus: (state) => state.productform.status,
    }),

    confTypes: function () {
      let types = this.types;

      types = types.filter((type) => {
        if (this.category) {
          return type.ref.parent.parent.id === this.category;
        } else return true;
      });

      types = orderBy(types, "name", "asc");

      if (this.user && this.user.role === "client") {
        types = types.filter((type) => {
          return type.employeeOnly ? false : true;
        });

        types = orderBy(
          types,
          (type) => {
            return this.$store.getters["request/rPackage"](
              this.user.requestPackage
            ).types.includes(type.id);
          },
          "desc"
        );
      }

      return types;
    },

    viewTypes: function () {
      if (
        this.user &&
        (this.user.role === "admin" ||
          this.user.manageRequests ||
          this.user.organization)
      )
        return true;
      else if (this.user && this.user.role === "client") {
        if (
          this.user.requestPackage ||
          (this.user.requestPackages && this.user.requestPackages.length)
        ) {
          return !this.gettingPackage;
        } else return false;
      } else return false;
    },
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    "status.uploading": function (val) {
      if (!val) {
        this.submitted = true;
        this.$store.commit("request/submittingState", false);
      }
    },

    user: {
      handler(user) {
        if (user && user.role === "client") {
          let promises = [];

          if (user.requestPackage) {
            promises.push(this.getPackage(user.requestPackage));
          }

          if (user.requestPackages && user.requestPackages.length) {
            user.requestPackages.forEach((rPackage) => {
              promises.push(this.getPackage(rPackage));
            });
          }

          Promise.all(promises).then(() => {
            this.gettingPackage = false;
          });
        }

        if (user && user.role === "client") {
          if (!this.$store.state.request.status.firstLoad)
            this.$store.dispatch("request/getRequests");
        }
      },
      immediate: true,
    },
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    TypeCard,
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions("types", ["getAllProducts", "getTypes"]),

    ...mapActions("request", ["getPackage"]),

    ...mapActions("productform", ["getFields"]),

    selectProduct() {
      this.getFields({
        type: this.$route.query.t,
        product: this.$route.query.p,
      });
    },

    // CHECK IF TYPE IS CLIENT
    // PLAN INCLUDED IN PACKAGE
    inPackage(id) {
      if (this.user && this.user.role === "client") {
        var packages = [];
        var included = false;

        if (this.user.requestPackages && this.user.requestPackages.length) {
          packages = packages.concat(this.user.requestPackages);
        } else if (this.user.requestPackage) {
          packages.push(this.user.requestPackage);
        }

        packages.forEach((pkg) => {
          if (this.$store.getters["request/rPackage"](pkg).types.includes(id)) {
            included = true;
          }
        });

        return included;
      } else {
        return true;
      }
    },

    // CHECK REQUEST LIMIT
    checkRequestLimit() {
      if (this.user.role === "client") {
        const requestLimit = this.user.allowedActiveRequests
          ? this.user.allowedActiveRequests
          : this.$store.getters["generalSettings/allowedActiveRequests"];
        if (
          requestLimit &&
          this.$store.getters["request/activeRequests"].length >= requestLimit
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.typeStatus.firstLoad) this.getTypes();
    if (!this.typeStatus.firstLoadAllProducts) this.getAllProducts();
  },
};
</script>